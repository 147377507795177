<template>


    <div>
        <v-text-field hide-details v-model="dates" label="Fechas" :prepend-icon="icon ? icons.mdiCalendar : null"
            readonly @click="modal = true" @click:clear="clear()" :clearable="indBorrar" dense outlined>
        </v-text-field>
       
        <v-dialog ref="dialogoref" v-model="modal" :return-value.sync="dates" persistent width="350">

            <v-date-picker v-model="dates" range locale="es-ve" :events="diasCerrados">
                <v-card-text class="mt-3">
                    <v-row>
                        <v-col class="text-center pt-0 pl-0 pr-0 pb-4">
                            
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="secondary" dark v-bind="attrs" v-on="on" block x-small>
                                        Rangos
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item  v-for="(item, index) in rangos" :key="index" link @click="seleccionarRango(item)">
                                        <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                        </v-col>


                    </v-row>
                    <v-row>
                        <v-col class="text-center  pt-0 pl-0 pr-0 pb-0">
                            <v-btn text color="primary" @click="modal = false">
                                Cancelar
                            </v-btn>
                            <v-btn text color="primary" @click="Seleccionar()">
                                Aceptar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-date-picker>
        </v-dialog>
    </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'
import moment from 'moment'
//import MaestroService from '@/api/servicios/MaestroService'
export default {
    props: {
        fechas: Array,
        icon: Boolean,
        indBorrar: Boolean,
    },
    setup(props, contex) {

        const dates = ref([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
        const modal = ref(false)
        const dialogoref = ref(null)
        const toggle_exclusive = ref()
        const rangoSelect = ref(1)
        const diasCerrados = ref([])
        const rangos = ref([
            { id: 1, nombre: 'Hoy', fechas: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')] },
            { id: 2, nombre: 'Ayer', fechas: [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')] },
            { id: 3, nombre: 'Esta Semana', fechas: [moment().startOf('week').subtract(-1, 'days').format('YYYY-MM-DD'), moment().endOf('week').subtract(-1, 'days').format('YYYY-MM-DD')] },
            { id: 4, nombre: 'Semana Pasada', fechas: [moment().subtract(1, 'week').startOf('week').subtract(-1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'week').endOf('week').subtract(-1, 'days').format('YYYY-MM-DD')] },
            { id: 5, nombre: 'Este Mes', fechas: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')] },
            { id: 6, nombre: 'Mes Pasado', fechas: [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')] }
        ])

        const seleccionarRango = (item)=>{
            dates.value = item.fechas
        }

       
/*
        const ArrayDiasCerrados = () => {
            MaestroService.ArrayDiasCerrados()
                .then(response => {
                    if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
                        diasCerrados.value = response.data.datos.split(',')
                    } else {
                        //alert(response.data.mensaje)
                    }

                })
                .catch(err => {
                    console.error(err)
                })
        }*/

        onBeforeMount(() => {
            if (props.fechas) {
                dates.value = props.fechas
            }
            //ArrayDiasCerrados()
        })

        const Seleccionar = () => {
            let array = []

            if (dates.value[1] != undefined) {

                const fecha1 =  (dates.value[0])
                const fecha2 =  (dates.value[1])
                if (fecha1 > fecha2) {

                    array = [dates.value[1], dates.value[0]]
                } else {
                    array = dates.value
                }
            } else {
                array = dates.value
            }

            dialogoref.value.save(array)

            contex.emit('getFechas', {
                desde: array[0],
                hasta: (array[1] != undefined ? array[1] : array[0])
            })
        }

        const clear = () => {
            dates.value = null
            contex.emit('getFechas', {
                desde: '',
                hasta: ''
            })
        }

        const valorText = () => {
            return dates.value.join(' ~ ')
        }


        return {
            icons: {
                mdiCalendar
            },
            modal,
            dates,
            Seleccionar,
            valorText,
            dialogoref,
            clear,
            toggle_exclusive,
            rangos,
            rangoSelect,
            diasCerrados,
            seleccionarRango
        }
    }
}
</script>