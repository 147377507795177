<template>
  <v-select
    v-model="select"
    :items="items"
    density="comfortable"
    hide-details="auto"
    :outlined="outlined"
    single-line
    :loading="cargando"
    item-text="nombre"
    item-value="id"
    label="Sucursales"
    :disabled="disabled"
    :rules="rules"
    :dense="outlined"
    :clearable="clearable"
    multiple
  >
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index < 2">
        <span>{{ item.nombre }}</span>
      </v-chip>
      <span v-if="index === 2" class="text-grey text-caption align-self-center">
        (+{{ select.length - 2 }} otros)
      </span>
    </template>
  </v-select>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'
import SucursalServices from '@/api/servicios/SucursalServices.js'
import store from '@/store'
export default {
  props: {
    value: Array,
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    icon: Boolean,
    todos: Boolean,
    default: Number,
    clearable: Boolean,
    padre: Number,
    rules: Array,
    label: { type: String, default: 'Sucursal' },
  },
  mounted() {
    this.modulo = this.$route.meta.modulo
    this.cargarDatos()
  },
  setup(props, contex) {
    const lista = ref([])
    const items = ref([])
    const modulo = ref(null)
    const select = ref([])
    const cargando = ref(false)

    watch(props, () => {
      select.value = props.value
    })
    onBeforeMount(() => {
      if (props.value != undefined) {
        select.value = props.value
      }
    })

    const selectId = id => {
      select.value = id
    }
    const cargarDatos = () => {
      lista.value = []
      cargando.value = true
      try {
        SucursalServices.ConsultarXModulo({
          idEmpresa: store.state.user.idEmpresa,
          idUsuario: store.state.user.idUsuario,
          idModulo: modulo.value,
        })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              lista.value = response.data.datos
              lista.value.forEach(item => {
                items.value.push({
                  id: item.id,
                  nombre: item.nombre,
                })
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargando.value = false
          })
      } catch (error) {
        cargando.value = false
      }
    }
    watch(select, () => {
      console.log('LISTA SUCURSALES', select.value)
      contex.emit('input', select.value)
    })

    return {
      icons: {
        mdiAlphaCCircle,
        mdiPlusCircleOutline,
      },
      lista,
      items,
      select,
      cargando,
      modulo,
      selectId,
      cargarDatos,
    }
  },
}
</script>