<template>
  <div>
    <p class="mb-6">Existencia por producto</p>
    <v-card>
      <v-card-text>
        <v-expand-transition>
          <div >
            <v-row>
              <v-col cols="4">
                <SelectMulti :outlined="true" :clearable="true" v-model="sucursalesSelect"></SelectMulti>
              </v-col>
              <v-col cols="4">
                <AreaSelect :clearable="true" v-model="filtros.IdArea" :dense="true" :outlined="true"></AreaSelect>
              </v-col>
    
              <v-col cols="4">
                <SelectCategoria :clearable="true" v-model="filtros.IdCategoria" :dense="true" :outlined="true" :add="false">
                </SelectCategoria>
              </v-col>
              <v-col cols="4">
                <SelectLinea :clearable="true" v-model="filtros.IdLinea" :dense="true" :outlined="true" :add="false"> </SelectLinea>
              </v-col>
              <!-- <v-col cols="4">
                <v-text-field
                  hide-details="auto"
                  v-model="filtros.barra"
                  label="Barra Producto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  hide-details="auto"
                  v-model="filtros.nombreProducto"
                  label="Nombre Producto"
                  outlined
                  dense
                ></v-text-field>
              </v-col> -->
              <v-col cols="4">
                <v-text-field
                  hide-details="auto"
                  v-model="filtros.NumeroLote"
                  label="Numero Lote"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  hide-details="auto"
                  v-model="filtros.IndExistencia"
                  :items="items"
                  dense
                  outlined
                  label="Existencia"
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
        <v-row>
          <v-col cols="12" md="6" lg="6">
            <v-row>
              <v-col cols="12" md="10" lg="10">
                <v-text-field
                  ref="NombreRef"
                  dense
                  outlined
                  label="Descripcion"
                  v-model="filtros.Valor"
                  autofocus
                  placeholder="Nombre/Descripcion/Barra/Ref..."
                  :loading="cargando"
                ></v-text-field>
              </v-col>
              
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn class="mx-1 my-1" small :loading="cargando" :disabled="cargando" color="secondary" @click="limpiar()">
              Limpiar
            </v-btn>
            <v-btn class="mx-1 my-1" :loading="cargando" small :disabled="cargando || !validar()" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <TablaPaginadaReporte
          @getOpciones="getOpciones" 
          @exportExcel="exportExcel"
          :acciones="['VER']"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL']"
          :otrasAcciones="otrasAcciones"
          :Cantidad = "Cantidad" 
          :Paginas = "Paginas"
          :cargando = "cargando"
        ></TablaPaginadaReporte>
      </v-card-text>
    </v-card>
  </div>
</template>
    
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPencil, mdiCheck, mdiCancel, mdiFilterOff, mdiFilter } from '@mdi/js'
import TablaPaginadaReporte from '@/components/TablaPaginadaReporte.vue'
import { computed, onBeforeMount, ref, watch } from '@vue/composition-api'
import store from '@/store'
import config from '@/api/config'
import AreaSelect from '../../archivo/area/componentes/AreaSelect.vue'
import DosFechas from '@/components/DosFechas.vue'
import FuncionesGenerales from '@/funciones/funciones'
import SelectMulti from '@/views/sistema/configuracion/sucursal/componentes/SelectMulti.vue'
import moment from 'moment'
import ExistenciaServices from '@/api/servicios/ExistenciaServices'
import SelectCategoria from '@/views/sistema/producto/maestro/categoria/componentes/Select.vue'
import SelectLinea from '@/views/sistema/producto/maestro/linea/componentes/Select.vue'
// demos

export default {
  components: { TablaPaginadaReporte, DosFechas, AreaSelect, SelectCategoria, SelectLinea, SelectMulti },
  setup() {
    const sucursalesSelect =  ref([])
    const Paginas = ref(0)
    const Pagina = ref(1)
    const LimiteProps = ref(10)
    const Cantidad = ref(0)
    const showFiltros = ref(false)
    const NombreRef = ref(null)
    const filtros = ref({
      IdSucursal: store.state.sucursalSelect.id,
      IdArea: -1,
      NumeroLote: '',
      IdLinea: -1,
      IdCategoria: -1,
      IndExistencia: 0,
      Valor:'',
      Cantidad: 0,
      Pagina: null,
      Limite: null,
      Columnas: null,
    })

    const items = [
      {
        value: 0,
        text: 'Todo',
      },
      {
        value: 1,
        text: 'Solo Existencia Positiva',
      },
      {
        value: 3,
        text: 'Solo Existencia 0',
      },
      {
        value: 2,
        text: 'Solo Existencia Negativa',
      },
      {
        value: 4,
        text: 'Solo Existencia Negativa y positivas',
      },
    ]
    const otrasAcciones = [
      { nombre: 'Activar', icon: mdiCheck, emit: 'GetBtn', color: 'success', ifValor: {columna: "indActivo"  , valor:false} }, 
      { nombre: 'Desactivar', icon: mdiCancel, emit: 'GetBtn', color: 'error', ifValor: {columna: "indActivo"  , valor:true} }
    ]
    const nombreReporte = ref(`Reporte existencia por producto ${moment().format('YYYYMMDDHHmmSS')}`)
    const cabecera = ref([
      { text: 'Id', value: 'productoSucursal.producto.id',  visible: true, columnaDB: 'Producto.id As ID' },
      { text: 'Sucursal', value: 'area.sucursal.nombre', width: '160px', visible: true, columnaDB: 'Sucursal.nombre As Sucursal' },
      { text: 'Area', value: 'area.nombre', width: '130px', visible: true, columnaDB: 'Area.nombre As Area' },
      { text: 'Referencia', value: 'productoSucursal.producto.referencia', format: { small: true }, visible: true, columnaDB:'Producto.referencia As Referencia' },
      { text: 'Barra', value: 'productoSucursal.producto.barra', format: { small: true }, visible: false, columnaDB:'Producto.barra As Barra' },
      { text: 'Producto', value: 'productoSucursal.producto.nombre', width: '200px', format: { small: true }, visible: true, columnaDB:'Producto.nombre As Producto' },
      { text: 'Lote', value: 'lote.numeroLote', width: '130px', format: { small: true }, visible: true, columnaDB: 'Lote.numeroLote As Lote' },
      { text: 'Existencia', value: 'inventario', width: '120px', align: 'right', visible: true, columnaDB: 'Existencia.inventario As Existencia' },
      { text: 'Costo', value: 'productoSucursal.costo', width: '100px', align: 'right', visible: true, columnaDB:'ProductoSucursal.costo As Costo' },
      { text: 'Pvp', value: 'productoSucursal.pvp', width: '100px', align: 'right', visible: true, columnaDB: 'ProductoSucursal.pvp As Pvp' },
      { text: 'Proveedor', value: 'productoSucursal.proveedor.razonSocial', width: '130px', visible: true, columnaDB:'Proveedor.razonSocial As Proveedor' },
      { text: 'Categoría', value: 'productoSucursal.producto.categoria.descripcion', width: '130px', visible: true, columnaDB:'Categoria.descripcion As Categoría' },
      { text: 'Linea', value: 'productoSucursal.producto.linea.descripcion', width: '130px', visible: true, columnaDB:'Linea.descripcion As Linea' },
    ])
    const datos = ref([])
    const cargando = ref(false)

    const validar = () => {
      let validado = true

      return validado
    }

    // onBeforeMount(() => {
    //   buscarDatos()
    // })

  
    const getOpciones = (opciones) => {
      Pagina.value = opciones.Pagina
      LimiteProps.value = opciones.LimiteFilas   
      buscarDatos();
    }

    

    const buscarDatos = () => {
      cargando.value = true

      ExistenciaServices.ConsultarExistenciaProductoReportePaginado({
        ...filtros.value,
        IdSucursal: sucursalesSelect.value.join(','), Pagina: Pagina.value, Limite: LimiteProps.value
      })
        .then(response => {
          if ((response.data.estatus = true)) {
            datos.value = response.data.datos.registros
            Paginas.value = response.data.datos.paginas
            Cantidad.value = response.data.datos.total
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'error',
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const getVer = dato => {
      console.log(dato)
    }

    const exportExcel = (columasSelect) => {
      // console.log({...filtros.value,  Columnas: columasSelect})
      // console.log(`${config.api_master}producto/consultar/excel?${FuncionesGenerales.recorrerObjeto({...filtros.value,  Columnas: columasSelect})}`)
      window.open(`${config.api_master}existencia/consultar/excel?${FuncionesGenerales.recorrerObjeto({...filtros.value,  Columnas: columasSelect})}`)
    }

    const limpiar = () => {
      datos.value = [];
      filtros.value = {};
      sucursalesSelect.value = [];
    }

    return {
      getVer,
      validar,
      buscarDatos,
      limpiar, 
      exportExcel,
      showFiltros,
      NombreRef,
      Paginas,
      Pagina,
      Cantidad,
      LimiteProps,
      otrasAcciones,
      cabecera,
      datos,
      cargando,
      nombreReporte,
      filtros,
      FuncionesGenerales,
      sucursalesSelect,
      items,
      getOpciones,
      icons: {
        mdiPencil,
        mdiFilterOff,
        mdiFilter,
        mdiCheck,
        mdiCancel
      },
    }
  },
}
</script>
    