//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class ExistenciaServices {

 
  ConsultarExistenciaProductoReporte(datos) {
    return http.get("Existencia/consultar/producto/reporte", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  ConsultarExistenciaProductoReportePaginado(datos) {
    return http.get("Existencia/consultar/producto/reporte/paginado", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
}


export default new ExistenciaServices();